import { get as settingsV4Get } from '@atlassian/jira-admin-pages-system-ui-announcement-banner-resources/src/services/settings-v4/index.tsx';
import { get as settingsGet } from '@atlassian/jira-admin-pages-system-ui-announcement-banner-resources/src/services/settings/index.tsx';
import { get as userDismissGet } from '@atlassian/jira-admin-pages-system-ui-announcement-banner-resources/src/services/user-dismiss/index.tsx';
import { createResource, useResource } from '@atlassian/react-resource-router';
import type { SettingsGetResource, SettingsGetV4Resource } from '../../../common/types';

export const announcementBannerSettingsResource = createResource<SettingsGetResource>({
	type: 'ANNOUNCEMENT_BANNER_SETTINGS',
	getKey: () => 'settings',
	getData: async (_, { tenantContext: { atlassianAccountId, isAnonymous } }) => {
		const [settings, hashIdLastDimissed] = await Promise.all([
			settingsGet(),
			userDismissGet(atlassianAccountId),
		]);

		return {
			...settings,
			isDismissable: isAnonymous ? false : settings?.isDismissable,
			isDismissed: isAnonymous ? false : settings?.hashId === hashIdLastDimissed,
		};
	},
	maxAge: 15 * 60 * 1000,
});

export const announcementBannerSettingsV4Resource = createResource<SettingsGetV4Resource>({
	type: 'ANNOUNCEMENT_BANNER_SETTINGS_V4',
	getKey: () => 'settings',
	getData: async (_, { tenantContext: { atlassianAccountId, isAnonymous } }) => {
		const [settings, hashIdLastDimissed] = await Promise.all([
			settingsV4Get(),
			userDismissGet(atlassianAccountId),
		]);

		return {
			...settings,
			isDismissable: isAnonymous ? false : settings?.isDismissable,
			isDismissed: isAnonymous ? false : settings?.hashId === hashIdLastDimissed,
		};
	},
	maxAge: 15 * 60 * 1000,
});

export const useAnnouncementBannerSettingsResource = () =>
	useResource<SettingsGetResource>(announcementBannerSettingsResource);

export const useAnnouncementBannerSettingsV4Resource = () =>
	useResource<SettingsGetV4Resource>(announcementBannerSettingsV4Resource);
