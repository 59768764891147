import React from 'react';
import AsyncOpenHelpPanelLinkThroughEventListener from '@atlassian/jira-help-panel-button/src/async';
import AsyncLegacyTeamProfileCard from '@atlassian/jira-profilecard-next/src/ui/team-profilecard-legacy/async';
import type { RouteContext } from '@atlassian/jira-router';
import { LegacyPage } from '@atlassian/jira-router-routes-common/src/ui/legacy-page/main.tsx';
import { TenantContextSubscriber } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';

type Props = RouteContext;

export const LegacyPageWithTeamProfileCardView = (props: Props) => (
	<>
		<LegacyPage {...props} />

		<AsyncOpenHelpPanelLinkThroughEventListener />

		<AsyncLegacyTeamProfileCard TenantContextSubscriber={TenantContextSubscriber} />
	</>
);
