import { globalLayout } from '@atlassian/jira-global-layout';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarFilters from '@atlassian/jira-navigation-apps-sidebar-filters/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { LegacyPage } from '@atlassian/jira-router-routes-common/src/ui/legacy-page/main.tsx';
import {
	globalIssueNavigatorRoute,
	globalIssueNavigatorBrowseRoute,
	globalIssueNavigatorRedirectRoute,
} from '@atlassian/jira-router-routes-legacy-global-issue-navigator-routes';
import { LegacyPageWithTeamProfileCardView } from './legacy-team-profile-card-view';

export const globalIssueNavigatorRouteEntry = createEntry(globalIssueNavigatorRoute, {
	canTransitionIn: () => false,
	component: LegacyPageWithTeamProfileCardView,
	layout: globalLayout,
	navigation: {
		menuId: MENU_ID.FILTERS,
		sidebar: AsyncSidebarFilters,
	},
	resources: [...getNavigationResources(), navigationSidebarGlobalResource],
});

export const globalIssueNavigatorBrowseRouteEntry = createEntry(globalIssueNavigatorBrowseRoute, {
	canTransitionIn: () => false,
	component: LegacyPage,
	layout: globalLayout,
	navigation: {
		menuId: MENU_ID.FILTERS,
		sidebar: AsyncSidebarFilters,
	},
	resources: [...getNavigationResources(), navigationSidebarGlobalResource],
});

export const globalIssueNavigatorRedirectRouteEntry = createEntry(
	globalIssueNavigatorRedirectRoute,
	{
		canTransitionIn: () => false,
		component: LegacyPage,
		layout: globalLayout,
		navigation: {
			menuId: MENU_ID.FILTERS,
			sidebar: AsyncSidebarFilters,
		},
		resources: [...getNavigationResources(), navigationSidebarGlobalResource],
	},
);
