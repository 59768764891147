// This doesn't use @atlassian/jira-navigation-system as it results in a hard-to-debug cycle.
// We need to move away from using these variables directly as it results in a brittle hard to evolve system.
// eslint-disable-next-line jira/restricted/@atlaskit+page-layout
import {
	LEFT_PANEL_WIDTH as UNSAFE_DO_NOT_USE_LEFT_PANEL_WIDTH,
	LEFT_SIDEBAR_WIDTH as UNSAFE_DO_NOT_USE_LEFT_SIDEBAR_WIDTH,
	RIGHT_PANEL_WIDTH as UNSAFE_DO_NOT_USE_RIGHT_PANEL_WIDTH,
	RIGHT_SIDEBAR_WIDTH as UNSAFE_DO_NOT_USE_RIGHT_SIDEBAR_WIDTH,
} from '@atlaskit/page-layout';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { PAGE_LAYOUT_OFFSET_TOP } from '@atlassian/jira-common-constants/src/page-layout';
import type {
	BusinessTheme,
	PartialBusinessTheme,
	ImageBackgroundConfig,
	ColorBackgroundConfig,
	GradientBackgroundConfig,
	OldThemeName,
	Token,
	OldBackgroundConfig,
} from './types';

export const PACKAGE_NAME = 'jiraBusinessThemeConstants';

export const STAR_ICON_COLOR = '#FFAB00';

export const Variables: Record<Token, string> = {
	BACKDROP_FILTER: '--jwm-backdrop-filter',
	COLOR_BACKGROUND_BRAND_BOLD: '--jwm-color-background-brand-bold',
	COLOR_BACKGROUND_BRAND_BOLD_HOVERED: '--jwm-color-background-brand-bold-hovered',
	COLOR_BACKGROUND_BRAND_BOLD_PRESSED: '--jwm-color-background-brand-bold-pressed',
	COLOR_BACKGROUND_DISABLED: '--jwm-color-background-disabled',
	COLOR_BACKGROUND_INPUT: '--jwm-background-input',
	COLOR_BACKGROUND_NEUTRAL: '--jwm-background-neutral',
	COLOR_BACKGROUND_NEUTRAL_HOVERED: '--jwm-background-neutral-hovered',
	COLOR_BACKGROUND_NEUTRAL_PRESSED: '--jwm-background-neutral-pressed',
	COLOR_BACKGROUND_NEUTRAL_SUBTLE_HOVERED: '--jwm-background-neutral-subtle-hovered',
	COLOR_BACKGROUND_NEUTRAL_SUBTLE_PRESSED: '--jwm-background-neutral-subtle-pressed',
	COLOR_BACKGROUND_SELECTED: '--jwm-background-selected',
	COLOR_BORDER: '--jwm-color-border',
	COLOR_BORDER_INPUT: '--jwm-color-border-input',
	COLOR_BORDER_INVERSE: '--jwm-color-border-inverse',
	COLOR_BORDER_SELECTED: '--jwm-color-border-selected',
	COLOR_ELEVATION_SURFACE: '--jwm-color-elevation-surface',
	COLOR_ICON_DANGER: '--jwm-color-icon-danger',
	COLOR_ICON_INFORMATION: '--jwm-color-icon-information',
	COLOR_ICON_SUCCESS: '--jwm-color-icon-success',
	COLOR_ICON_WARNING: '--jwm-color-icon-warning',
	COLOR_LINK: '--jwm-color-link',
	COLOR_LINK_PRESSED: '--jwm-color-link-pressed',
	COLOR_SKELETON: '--jwm-skeleton',
	COLOR_TEXT: '--jwm-color-text',
	COLOR_TEXT_DISABLED: '--jwm-color-text-disabled',
	COLOR_TEXT_INVERSE: '--jwm-color-text-inverse',
	COLOR_TEXT_SELECTED: '--jwm-color-text-selected',
	COLOR_TEXT_SUBTLE: '--jwm-color-text-subtle',
	COLOR_TEXT_SUBTLEST: '--jwm-color-text-subtlest',
	CONTENT_HEIGHT: '--jwm-content-height',
	CONTENT_WIDTH: '--jwm-content-width',
	ELEVATION_SURFACE: '--jwm-elevation-surface',
	ELEVATION_SURFACE_CONTRAST: '--jwm-elevation-surface-contrast',
	ELEVATION_SURFACE_CONTRAST_BOLDER: '--jwm-elevation-surface-contrast-bolder',
	WRAPPER_BACKGROUND: '--jwm-wrapper-background',
	WRAPPER_BORDER_RADIUS: '--jwm-wrapper-border-radius',
	WRAPPER_HEIGHT: '--jwm-wrapper-height',
	WRAPPER_PADDING: '--jwm-wrapper-padding',
	WRAPPER_WIDTH: '--jwm-wrapper-width',
	Z_INDEX: '--jwm-z-index',
};

export const Tokens: Record<Token, string> = {
	BACKDROP_FILTER: 'var(--jwm-backdrop-filter)',
	COLOR_BACKGROUND_BRAND_BOLD: 'var(--jwm-color-background-brand-bold)',
	COLOR_BACKGROUND_BRAND_BOLD_HOVERED: 'var(--jwm-color-background-brand-bold-hovered)',
	COLOR_BACKGROUND_BRAND_BOLD_PRESSED: 'var(--jwm-color-background-brand-bold-pressed)',
	COLOR_BACKGROUND_DISABLED: 'var(--jwm-color-background-disabled)',
	COLOR_BACKGROUND_INPUT: 'var(--jwm-background-input)',
	COLOR_BACKGROUND_NEUTRAL: 'var(--jwm-background-neutral)',
	COLOR_BACKGROUND_NEUTRAL_HOVERED: 'var(--jwm-background-neutral-hovered)',
	COLOR_BACKGROUND_NEUTRAL_PRESSED: 'var(--jwm-background-neutral-pressed)',
	COLOR_BACKGROUND_NEUTRAL_SUBTLE_HOVERED: 'var(--jwm-background-neutral-subtle-hovered)',
	COLOR_BACKGROUND_NEUTRAL_SUBTLE_PRESSED: 'var(--jwm-background-neutral-subtle-pressed)',
	COLOR_BACKGROUND_SELECTED: 'var(--jwm-background-selected)',
	COLOR_BORDER: 'var(--jwm-color-border)',
	COLOR_BORDER_INPUT: 'var(--jwm-color-border-input)',
	COLOR_BORDER_INVERSE: 'var(--jwm-color-border-inverse)',
	COLOR_BORDER_SELECTED: 'var(--jwm-color-border-selected)',
	COLOR_ELEVATION_SURFACE: 'var(--jwm-color-elevation-surface)',
	COLOR_ICON_DANGER: 'var(--jwm-color-icon-danger)',
	COLOR_ICON_INFORMATION: 'var(--jwm-color-icon-information)',
	COLOR_ICON_SUCCESS: 'var(--jwm-color-icon-success)',
	COLOR_ICON_WARNING: 'var(--jwm-color-icon-warning)',
	COLOR_LINK: 'var(--jwm-color-link)',
	COLOR_LINK_PRESSED: 'var(--jwm-color-link-pressed)',
	COLOR_SKELETON: 'var(--jwm-skeleton)',
	COLOR_TEXT: 'var(--jwm-color-text)',
	COLOR_TEXT_DISABLED: 'var(--jwm-color-text-disabled)',
	COLOR_TEXT_INVERSE: 'var(--jwm-color-text-inverse)',
	COLOR_TEXT_SELECTED: 'var(--jwm-color-text-selected)',
	COLOR_TEXT_SUBTLE: 'var(--jwm-color-text-subtle)',
	COLOR_TEXT_SUBTLEST: 'var(--jwm-color-text-subtlest)',
	CONTENT_HEIGHT: 'var(--jwm-content-height)',
	CONTENT_WIDTH: 'var(--jwm-content-width)',
	ELEVATION_SURFACE: 'var(--jwm-elevation-surface)',
	ELEVATION_SURFACE_CONTRAST: 'var(--jwm-elevation-surface-contrast)',
	ELEVATION_SURFACE_CONTRAST_BOLDER: 'var(--jwm-elevation-surface-contrast-bolder)',
	WRAPPER_BACKGROUND: 'var(--jwm-wrapper-background)',
	WRAPPER_BORDER_RADIUS: 'var(--jwm-wrapper-border-radius)',
	WRAPPER_HEIGHT: 'var(--jwm-wrapper-height)',
	WRAPPER_PADDING: 'var(--jwm-wrapper-padding)',
	WRAPPER_WIDTH: 'var(--jwm-wrapper-width)',
	Z_INDEX: 'var(--jwm-z-index)',
};

export const BASE_THEME: BusinessTheme = {
	'color.background.brand.bold': token('color.background.brand.bold', '#09326C'),
	'color.background.brand.bold.hovered': token('color.background.brand.bold.hovered', '#0C66E4'),
	'color.background.brand.bold.pressed': token('color.background.brand.bold.pressed', '#09326C'),
	'color.background.disabled': token('color.background.disabled', '#091E4208'),
	'color.background.input': token('color.background.input', colors.N0),
	'color.background.neutral': token('color.background.neutral', colors.N30A),
	'color.background.neutral.hovered': token('color.background.neutral.hovered', colors.N40A),
	'color.background.neutral.pressed': token('color.background.neutral.pressed', colors.N50A),
	'color.background.neutral.subtle.hovered': token(
		'color.background.neutral.subtle.hovered',
		colors.N40A,
	),
	'color.background.neutral.subtle.pressed': token(
		'color.background.neutral.subtle.pressed',
		colors.N50A,
	),
	'color.background.selected': token('color.background.selected', colors.B400),
	'color.elevation.surface': token('elevation.surface', colors.N0),
	'color.border': token('color.border', colors.N40A),
	'color.border.input': token('color.border.input', '#091E4224'),
	'color.border.inverse': token('color.border.inverse', colors.N0),
	'color.border.selected': token('color.border.selected', colors.B400),
	'color.icon.danger': token('color.icon.danger', colors.R200),
	'color.icon.information': token('color.icon.information', colors.B200),
	'color.icon.success': token('color.icon.success', colors.G200),
	'color.icon.warning': token('color.icon.warning', colors.Y200),
	'color.link': token('color.link', '#0c66e4'),
	'color.link.pressed': token('color.link.pressed', '#0055cc'),
	'color.skeleton': token('color.background.neutral', colors.N20A),
	'color.text': token('color.text', colors.N800),
	'color.text.disabled': token('color.text.disabled', '#091E424F'),
	'color.text.inverse': token('color.text.inverse', colors.N0),
	'color.text.selected': token('color.text.selected', colors.B400),
	'color.text.subtle': token('color.text.subtle', colors.N500),
	'color.text.subtlest': token('color.text.subtlest', '#626f86'),
	'elevation.surface.contrast': 'transparent',
	'elevation.surface.contrast.bolder': 'transparent',
};

export const HIGH_BRIGHTNESS_THEME: PartialBusinessTheme = {
	'color.background.brand.bold': token('color.background.accent.gray.bolder', '#626F86'),
	'color.background.brand.bold.hovered': token(
		'color.background.accent.gray.bolder.hovered',
		'#44546F',
	),
	'color.background.brand.bold.pressed': token(
		'color.background.accent.gray.bolder.pressed',
		'#2C3E5D',
	),
	'color.background.disabled': token('color.background.neutral', '#091E420F'),
	'color.background.input': 'rgba(255, 255, 255, 0.8)',
	'color.background.selected': token('color.background.accent.gray.bolder.pressed', '#2C3E5D'),
	'color.text.selected': token('color.text.inverse', colors.N0),
};

export const LOW_BRIGHTNESS_THEME: PartialBusinessTheme = {
	'color.background.brand.bold': 'rgba(255, 255, 255, 0.8)',
	'color.background.brand.bold.hovered': 'rgba(255, 255, 255, 0.6)',
	'color.background.brand.bold.pressed': 'rgba(255, 255, 255, 0.4)',
	'color.background.disabled': 'rgba(255, 255, 255, 0.08)',
	'color.background.input': 'rgba(255, 255, 255, 0.08)',
	'color.background.neutral': 'rgba(255, 255, 255, 0.08)',
	'color.background.neutral.hovered': 'rgba(255, 255, 255, 0.16)',
	'color.background.neutral.pressed': 'rgba(255, 255, 255, 0.28)',
	'color.background.neutral.subtle.hovered': 'rgba(255, 255, 255, 0.08)',
	'color.background.neutral.subtle.pressed': 'rgba(255, 255, 255, 0.16)',
	'color.background.selected': 'rgba(255, 255, 255, 0.6)',
	'color.elevation.surface': '#172b4d',
	'color.border': 'rgba(255, 255, 255, 0.14)',
	'color.border.input': 'rgba(255, 255, 255, 0.3)',
	'color.border.inverse': 'rgba(255, 255, 255, 0.3)',
	'color.border.selected': colors.N0,
	'color.icon.danger': colors.N0,
	'color.icon.information': colors.N0,
	'color.icon.success': colors.N0,
	'color.icon.warning': colors.N0,
	'color.skeleton': 'rgba(255,255,255,0.08)',
	'color.text': colors.N0,
	'color.text.disabled': 'rgba(255, 255, 255, 0.6)',
	'color.text.inverse': '#172B4D', // Neutral 1000
	'color.text.selected': '#172B4D', // Neutral 1000
	'color.text.subtle': 'rgba(255, 255, 255, 0.8)',
	'color.text.subtlest': 'rgba(255, 255, 255, 0.8)',
};

export const GRADIENT_THEME: PartialBusinessTheme = {
	'color.link': '#CCE0FF', // Blue 200
	'color.link.pressed': '#E9F2FF', // Blue 100
};

// Image
export const IMAGE_BACKGROUND_CONFIG: ImageBackgroundConfig = {
	name: 'image',
	brightness: 'high',
	theme: {
		light: {
			'color.background.brand.bold': token('color.background.accent.gray.bolder', '#626F86'),
			'color.background.brand.bold.hovered': token(
				'color.background.accent.gray.bolder.hovered',
				'#44546F',
			),
			'color.background.brand.bold.pressed': token(
				'color.background.accent.gray.bolder.pressed',
				'#2C3E5D',
			),
			'color.background.selected': token('color.background.accent.gray.bolder.pressed', '#2C3E5D'),
			'color.link': token('color.text.accent.blue.bolder', '#09326C'),
			'color.link.pressed': token('color.text.accent.blue', '#0055CC'),
			'color.text.selected': token('color.text.inverse', colors.N0),
			'elevation.surface.contrast': 'rgba(255,255,255,0.82)',
			'elevation.surface.contrast.bolder': token('elevation.surface', colors.N0),
		},
		dark: {
			'color.background.brand.bold': token('color.background.accent.gray.bolder', '#8C9BAB'),
			'color.background.brand.bold.hovered': token(
				'color.background.accent.gray.bolder.hovered',
				'#9FADBC',
			),
			'color.background.brand.bold.pressed': token(
				'color.background.accent.gray.bolder.pressed',
				'#B6C2CF',
			),
			'color.background.input': 'rgba(34, 39, 43, 0.8)',
			'color.background.selected': token('color.background.accent.gray.bolder.pressed', '#B6C2CF'),
			'color.elevation.surface': '#172b4d',
			'color.border': 'rgba(255, 255, 255, 0.14)',
			'color.border.input': 'rgba(255, 255, 255, 0.3)',
			'color.border.inverse': 'rgba(255, 255, 255, 0.3)',
			'color.icon.danger': colors.N0,
			'color.icon.information': colors.N0,
			'color.icon.success': colors.N0,
			'color.icon.warning': colors.N0,
			'color.link': token('color.text.accent.blue.bolder', '#CCE0FF'),
			'color.link.pressed': token('color.text.accent.blue', '#85B8FF'),
			'color.skeleton': 'rgba(255,255,255,0.8)',
			'color.text': colors.N0,
			'color.text.selected': token('color.text.inverse', '#1D2125'),
			'elevation.surface.contrast': 'rgba(29, 33, 37, 0.82)',
			'elevation.surface.contrast.bolder': token('elevation.surface', '#1D2125'),
		},
	},
};

// Old Colors
const WHITE_BACKGROUND_CONFIG: OldBackgroundConfig = {
	name: 'white',
};

const LIGHT_PURPLE_BACKGROUND_CONFIG: OldBackgroundConfig = {
	name: 'light-purple',
};

const LIGHT_BLUE_BACKGROUND_CONFIG: OldBackgroundConfig = {
	name: 'light-blue',
};

const LIGHT_TEAL_BACKGROUND_CONFIG: OldBackgroundConfig = {
	name: 'light-teal',
};

const LIGHT_GREEN_BACKGROUND_CONFIG: OldBackgroundConfig = {
	name: 'light-green',
};

const LIGHT_YELLOW_BACKGROUND_CONFIG: OldBackgroundConfig = {
	name: 'light-yellow',
};

const LIGHT_RED_BACKGROUND_CONFIG: OldBackgroundConfig = {
	name: 'light-red',
};

const LIGHT_MAGENTA_BACKGROUND_CONFIG: OldBackgroundConfig = {
	name: 'light-magenta',
};

const LIGHT_ORANGE_BACKGROUND_CONFIG: OldBackgroundConfig = {
	name: 'light-orange',
};

const DARK_BACKGROUND_CONFIG: OldBackgroundConfig = {
	name: 'dark',
};

const PURPLE_BACKGROUND_CONFIG: OldBackgroundConfig = {
	name: 'purple',
};

const BLUE_BACKGROUND_CONFIG: OldBackgroundConfig = {
	name: 'blue',
};

const TEAL_BACKGROUND_CONFIG: OldBackgroundConfig = {
	name: 'teal',
};

const GREEN_BACKGROUND_CONFIG: OldBackgroundConfig = {
	name: 'green',
};

const YELLOW_BACKGROUND_CONFIG: OldBackgroundConfig = {
	name: 'yellow',
};

const RED_BACKGROUND_CONFIG: OldBackgroundConfig = {
	name: 'red',
};

const MAGENTA_BACKGROUND_CONFIG: OldBackgroundConfig = {
	name: 'magenta',
};

const ORANGE_BACKGROUND_CONFIG: OldBackgroundConfig = {
	name: 'orange',
};

// New Colors
const DARKER_GRAY_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'darker-gray',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#44546F', // Neutral 800
			'color.link': '#F1F2F4', // Neutral 200
			'color.link.pressed': '#F7F8F9', // Neutral 100
		},
		dark: {
			'color.elevation.surface': '#44546F', // Neutral 800
			'color.link': '#F1F2F4', // Neutral 200
			'color.link.pressed': '#F7F8F9', // Neutral 100
		},
	},
};

const DARKER_PURPLE_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'darker-purple',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#5E4DB2', // Purple 800
			'color.link': '#DFD8FD', // Purple 200
			'color.link.pressed': '#F3F0FF', // Purple 100
		},
		dark: {
			'color.elevation.surface': '#5E4DB2', // Purple 800
			'color.link': '#DFD8FD', // Purple 200
			'color.link.pressed': '#F3F0FF', // Purple 100
		},
	},
};

const DARKER_BLUE_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'darker-blue',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#0055CC', // Blue 800
			'color.link': '#CCE0FF', // Blue 200
			'color.link.pressed': '#E9F2FF', // Blue 100
		},
		dark: {
			'color.elevation.surface': '#0055CC', // Blue 800
			'color.link': '#CCE0FF', // Blue 200
			'color.link.pressed': '#E9F2FF', // Blue 100
		},
	},
};

const DARKER_TEAL_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'darker-teal',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#206A83', // Teal 800
			'color.link': '#C6EDFB', // Teal 200
			'color.link.pressed': '#E7F9FF', // Teal 100
		},
		dark: {
			'color.elevation.surface': '#206A83', // Teal 800
			'color.link': '#C6EDFB', // Teal 200
			'color.link.pressed': '#E7F9FF', // Teal 100
		},
	},
};

const DARKER_GREEN_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'darker-green',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#216E4E', // Green 800
			'color.link': '#BAF3DB', // Green 200
			'color.link.pressed': '#DCFFF1', // Green 100
		},
		dark: {
			'color.elevation.surface': '#216E4E', // Green 800
			'color.link': '#BAF3DB', // Green 200
			'color.link.pressed': '#DCFFF1', // Green 100
		},
	},
};

const DARKER_LIME_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'darker-lime',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#4C6B1F', // Lime 800
			'color.link': '#D3F1A7', // Lime 200
			'color.link.pressed': '#EFFFD6', // Lime 100
		},
		dark: {
			'color.elevation.surface': '#4C6B1F', // Lime 800
			'color.link': '#D3F1A7', // Lime 200
			'color.link.pressed': '#EFFFD6', // Lime 100
		},
	},
};

const DARKER_YELLOW_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'darker-yellow',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#7F5F01', // Yellow 800
			'color.link': '#F8E6A0', // Yellow 200
			'color.link.pressed': '#FFF7D6', // Yellow 100
		},
		dark: {
			'color.elevation.surface': '#7F5F01', // Yellow 800
			'color.link': '#F8E6A0', // Yellow 200
			'color.link.pressed': '#FFF7D6', // Yellow 100
		},
	},
};

const DARKER_ORANGE_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'darker-orange',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#A54800', // Orange 800
			'color.link': '#FEDEC8', // Orange 200
			'color.link.pressed': '#FFF3EB', // Orange 100
		},
		dark: {
			'color.elevation.surface': '#A54800', // Orange 800
			'color.link': '#FEDEC8', // Orange 200
			'color.link.pressed': '#FFF3EB', // Orange 100
		},
	},
};

const DARKER_RED_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'darker-red',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#AE2E24', // Red 800
			'color.link': '#FFD5D2', // Red 200
			'color.link.pressed': '#FFECEB', // Red 100
		},
		dark: {
			'color.elevation.surface': '#AE2E24', // Red 800
			'color.link': '#FFD5D2', // Red 200
			'color.link.pressed': '#FFECEB', // Red 100
		},
	},
};

const DARKER_MAGENTA_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'darker-magenta',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#943D73', // Magenta 800
			'color.link': '#FDD0EC', // Magenta 200
			'color.link.pressed': '#FFECF8', // Magenta 100
		},
		dark: {
			'color.elevation.surface': '#943D73', // Magenta 800
			'color.link': '#FDD0EC', // Magenta 200
			'color.link.pressed': '#FFECF8', // Magenta 100
		},
	},
};

const DARKEST_GRAY_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'darkest-gray',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#172B4D', // Neutral 1000
			'color.link': '#F1F2F4', // Neutral 200
			'color.link.pressed': '#F7F8F9', // Neutral 100
		},
		dark: {
			'color.elevation.surface': '#172B4D', // Neutral 1000
			'color.link': '#F1F2F4', // Neutral 200
			'color.link.pressed': '#F7F8F9', // Neutral 100
		},
	},
};

const DARKEST_PURPLE_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'darkest-purple',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#352C63', // Purple 900
			'color.link': '#DFD8FD', // Purple 200
			'color.link.pressed': '#F3F0FF', // Purple 100
		},
		dark: {
			'color.elevation.surface': '#352C63', // Purple 900
			'color.link': '#DFD8FD', // Purple 200
			'color.link.pressed': '#F3F0FF', // Purple 100
		},
	},
};

const DARKEST_BLUE_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'darkest-blue',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#09326C', // Blue 900
			'color.link': '#CCE0FF', // Blue 200
			'color.link.pressed': '#E9F2FF', // Blue 100
		},
		dark: {
			'color.elevation.surface': '#09326C', // Blue 900
			'color.link': '#CCE0FF', // Blue 200
			'color.link.pressed': '#E9F2FF', // Blue 100
		},
	},
};

const DARKEST_TEAL_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'darkest-teal',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#164555', // Teal 900
			'color.link': '#C6EDFB', // Teal 200
			'color.link.pressed': '#E7F9FF', // Teal 100
		},
		dark: {
			'color.elevation.surface': '#164555', // Teal 900
			'color.link': '#C6EDFB', // Teal 200
			'color.link.pressed': '#E7F9FF', // Teal 100
		},
	},
};

const DARKEST_GREEN_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'darkest-green',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#164B35', // Green 900
			'color.link': '#BAF3DB', // Green 200
			'color.link.pressed': '#DCFFF1', // Green 100
		},
		dark: {
			'color.elevation.surface': '#164B35', // Green 900
			'color.link': '#BAF3DB', // Green 200
			'color.link.pressed': '#DCFFF1', // Green 100
		},
	},
};

const DARKEST_LIME_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'darkest-lime',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#37471F', // Lime 900
			'color.link': '#D3F1A7', // Lime 200
			'color.link.pressed': '#EFFFD6', // Lime 100
		},
		dark: {
			'color.elevation.surface': '#37471F', // Lime 900
			'color.link': '#D3F1A7', // Lime 200
			'color.link.pressed': '#EFFFD6', // Lime 100
		},
	},
};

const DARKEST_YELLOW_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'darkest-yellow',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#533F04', // Yellow 900
			'color.link': '#F8E6A0', // Yellow 200
			'color.link.pressed': '#FFF7D6', // Yellow 100
		},
		dark: {
			'color.elevation.surface': '#533F04', // Yellow 900
			'color.link': '#F8E6A0', // Yellow 200
			'color.link.pressed': '#FFF7D6', // Yellow 100
		},
	},
};

const DARKEST_ORANGE_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'darkest-orange',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#702E00', // Orange 900
			'color.link': '#FEDEC8', // Orange 200
			'color.link.pressed': '#FFF3EB', // Orange 100
		},
		dark: {
			'color.elevation.surface': '#702E00', // Orange 900
			'color.link': '#FEDEC8', // Orange 200
			'color.link.pressed': '#FFF3EB', // Orange 100
		},
	},
};

const DARKEST_RED_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'darkest-red',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#5D1F1A', // Red 900
			'color.link': '#FFD5D2', // Red 200
			'color.link.pressed': '#FFECEB', // Red 100
		},
		dark: {
			'color.elevation.surface': '#5D1F1A', // Red 900
			'color.link': '#FFD5D2', // Red 200
			'color.link.pressed': '#FFECEB', // Red 100
		},
	},
};

const DARKEST_MAGENTA_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'darkest-magenta',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#50253F', // Magenta 900
			'color.link': '#FDD0EC', // Magenta 200
			'color.link.pressed': '#FFECF8', // Magenta 100
		},
		dark: {
			'color.elevation.surface': '#50253F', // Magenta 900
			'color.link': '#FDD0EC', // Magenta 200
			'color.link.pressed': '#FFECF8', // Magenta 100
		},
	},
};

const LIGHTEST_GRAY_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'lightest-gray',
	brightness: 'high',
	theme: {
		light: {
			'color.elevation.surface': '#F1F2F4', // Neutral 200
			'color.link': '#44546F', // Neutral 800
			'color.link.pressed': '#091E42', // Neutral 1100
		},
		dark: {
			'color.elevation.surface': '#44546F', // Neutral 800
			'color.link': '#F1F2F4', // Neutral 200
			'color.link.pressed': '#F7F8F9', // Neutral 100
		},
	},
};

const LIGHTEST_PURPLE_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'lightest-purple',
	brightness: 'high',
	theme: {
		light: {
			'color.elevation.surface': '#F3F0FF', // Purple 100
			'color.link': '#5E4DB2', // Purple 800
			'color.link.pressed': '#352C63', // Purple 900
		},
		dark: {
			'color.elevation.surface': '#5E4DB2', // Purple 800
			'color.link': '#DFD8FD', // Purple 200
			'color.link.pressed': '#F3F0FF', // Purple 100
		},
	},
};

const LIGHTEST_BLUE_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'lightest-blue',
	brightness: 'high',
	theme: {
		light: {
			'color.elevation.surface': '#E9F2FF', // Blue 100
			'color.link': '#0055CC', // Blue 800
			'color.link.pressed': '#09326C', // Blue 900
		},
		dark: {
			'color.elevation.surface': '#0055CC', // Blue 800
			'color.link': '#CCE0FF', // Blue 200
			'color.link.pressed': '#E9F2FF', // Blue 100
		},
	},
};

const LIGHTEST_TEAL_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'lightest-teal',
	brightness: 'high',
	theme: {
		light: {
			'color.elevation.surface': '#E7F9FF', // Teal 100
			'color.link': '#206A83', // Teal 800
			'color.link.pressed': '#164555', // Teal 900
		},
		dark: {
			'color.elevation.surface': '#206A83', // Teal 800
			'color.link': '#C6EDFB', // Teal 200
			'color.link.pressed': '#E7F9FF', // Teal 100
		},
	},
};

const LIGHTEST_GREEN_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'lightest-green',
	brightness: 'high',
	theme: {
		light: {
			'color.elevation.surface': '#DCFFF1', // Green 100
			'color.link': '#216E4E', // Green 800
			'color.link.pressed': '#164B35', // Green 900
		},
		dark: {
			'color.elevation.surface': '#216E4E', // Green 800
			'color.link': '#BAF3DB', // Green 200
			'color.link.pressed': '#DCFFF1', // Green 100
		},
	},
};

const LIGHTEST_LIME_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'lightest-lime',
	brightness: 'high',
	theme: {
		light: {
			'color.elevation.surface': '#EFFFD6', // Lime 100
			'color.link': '#4C6B1F', // Lime 800
			'color.link.pressed': '#37471F', // Lime 900
		},
		dark: {
			'color.elevation.surface': '#4C6B1F', // Lime 800
			'color.link': '#D3F1A7', // Lime 200
			'color.link.pressed': '#EFFFD6', // Lime 100
		},
	},
};

const LIGHTEST_YELLOW_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'lightest-yellow',
	brightness: 'high',
	theme: {
		light: {
			'color.elevation.surface': '#FFF7D6', // Yellow 100
			'color.link': '#7F5F01', // Yellow 800
			'color.link.pressed': '#533F04', // Yellow 900
		},
		dark: {
			'color.elevation.surface': '#7F5F01', // Yellow 800
			'color.link': '#F8E6A0', // Yellow 200
			'color.link.pressed': '#FFF7D6', // Yellow 100
		},
	},
};

const LIGHTEST_ORANGE_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'lightest-orange',
	brightness: 'high',
	theme: {
		light: {
			'color.elevation.surface': '#FFF3EB', // Orange 100
			'color.link': '#A54800', // Orange 800
			'color.link.pressed': '#702E00', // Orange 900
		},
		dark: {
			'color.elevation.surface': '#A54800', // Orange 800
			'color.link': '#FEDEC8', // Orange 200
			'color.link.pressed': '#FFF3EB', // Orange 100
		},
	},
};

const LIGHTEST_RED_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'lightest-red',
	brightness: 'high',
	theme: {
		light: {
			'color.elevation.surface': '#FFECEB', // Red 100
			'color.link': '#AE2E24', // Red 800
			'color.link.pressed': '#5D1F1A', // Red 900
		},
		dark: {
			'color.elevation.surface': '#AE2E24', // Red 800
			'color.link': '#FFD5D2', // Red 200
			'color.link.pressed': '#FFECEB', // Red 100
		},
	},
};

const LIGHTEST_MAGENTA_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'lightest-magenta',
	brightness: 'high',
	theme: {
		light: {
			'color.elevation.surface': '#FFECF8', // Magenta 100
			'color.link': '#943D73', // Magenta 800
			'color.link.pressed': '#50253F', // Magenta 900
		},
		dark: {
			'color.elevation.surface': '#943D73', // Magenta 800
			'color.link': '#FDD0EC', // Magenta 200
			'color.link.pressed': '#FFECF8', // Magenta 100
		},
	},
};

const LIGHTER_GRAY_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'lighter-gray',
	brightness: 'high',
	theme: {
		light: {
			'color.elevation.surface': '#DCDFE4', // Neutral 200
			'color.link': '#44546F', // Neutral 800
			'color.link.pressed': '#091E42', // Neutral 1100
		},
		dark: {
			'color.elevation.surface': '#172B4D', // Neutral 1000
			'color.link': '#F1F2F4', // Neutral 200
			'color.link.pressed': '#F7F8F9', // Neutral 100
		},
	},
};

const LIGHTER_PURPLE_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'lighter-purple',
	brightness: 'high',
	theme: {
		light: {
			'color.elevation.surface': '#DFD8FD', // Purple 200
			'color.link': '#5E4DB2', // Purple 800
			'color.link.pressed': '#352C63', // Purple 900
		},
		dark: {
			'color.elevation.surface': '#352C63', // Purple 900
			'color.link': '#DFD8FD', // Purple 200
			'color.link.pressed': '#F3F0FF', // Purple 100
		},
	},
};

const LIGHTER_BLUE_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'lighter-blue',
	brightness: 'high',
	theme: {
		light: {
			'color.elevation.surface': '#CCE0FF', // Blue 200
			'color.link': '#0055CC', // Blue 800
			'color.link.pressed': '#09326C', // Blue 900
		},
		dark: {
			'color.elevation.surface': '#09326C', // Blue 900
			'color.link': '#CCE0FF', // Blue 200
			'color.link.pressed': '#E9F2FF', // Blue 100
		},
	},
};

const LIGHTER_TEAL_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'lighter-teal',
	brightness: 'high',
	theme: {
		light: {
			'color.elevation.surface': '#C6EDFB', // Teal 200
			'color.link': '#206A83', // Teal 800
			'color.link.pressed': '#164555', // Teal 900
		},
		dark: {
			'color.elevation.surface': '#164555', // Teal 900
			'color.link': '#C6EDFB', // Teal 200
			'color.link.pressed': '#E7F9FF', // Teal 100
		},
	},
};

const LIGHTER_GREEN_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'lighter-green',
	brightness: 'high',
	theme: {
		light: {
			'color.elevation.surface': '#BAF3DB', // Green 200
			'color.link': '#216E4E', // Green 800
			'color.link.pressed': '#164B35', // Green 900
		},
		dark: {
			'color.elevation.surface': '#164B35', // Green 900
			'color.link': '#BAF3DB', // Green 200
			'color.link.pressed': '#DCFFF1', // Green 100
		},
	},
};

const LIGHTER_LIME_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'lighter-lime',
	brightness: 'high',
	theme: {
		light: {
			'color.elevation.surface': '#D3F1A7', // Lime 200
			'color.link': '#4C6B1F', // Lime 800
			'color.link.pressed': '#37471F', // Lime 900
		},
		dark: {
			'color.elevation.surface': '#37471F', // Lime 900
			'color.link': '#D3F1A7', // Lime 200
			'color.link.pressed': '#EFFFD6', // Lime 100
		},
	},
};

const LIGHTER_YELLOW_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'lighter-yellow',
	brightness: 'high',
	theme: {
		light: {
			'color.elevation.surface': '#F8E6A0', // Yellow 200
			'color.link': '#7F5F01', // Yellow 800
			'color.link.pressed': '#533F04', // Yellow 900
		},
		dark: {
			'color.elevation.surface': '#533F04', // Yellow 900
			'color.link': '#F8E6A0', // Yellow 200
			'color.link.pressed': '#FFF7D6', // Yellow 100
		},
	},
};

const LIGHTER_ORANGE_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'lighter-orange',
	brightness: 'high',
	theme: {
		light: {
			'color.elevation.surface': '#FEDEC8', // Orange 200
			'color.link': '#A54800', // Orange 800
			'color.link.pressed': '#702E00', // Orange 900
		},
		dark: {
			'color.elevation.surface': '#702E00', // Orange 900
			'color.link': '#FEDEC8', // Orange 200
			'color.link.pressed': '#FFF3EB', // Orange 100
		},
	},
};

const LIGHTER_RED_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'lighter-red',
	brightness: 'high',
	theme: {
		light: {
			'color.elevation.surface': '#FFD5D2', // Red 200
			'color.link': '#AE2E24', // Red 800
			'color.link.pressed': '#5D1F1A', // Red 900
		},
		dark: {
			'color.elevation.surface': '#5D1F1A', // Red 900
			'color.link': '#FFD5D2', // Red 200
			'color.link.pressed': '#FFECEB', // Red 100
		},
	},
};

const LIGHTER_MAGENTA_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'lighter-magenta',
	brightness: 'high',
	theme: {
		light: {
			'color.elevation.surface': '#FDD0EC', // Magenta 200
			'color.link': '#943D73', // Magenta 800
			'color.link.pressed': '#50253F', // Magenta 900
		},
		dark: {
			'color.elevation.surface': '#50253F', // Magenta 900
			'color.link': '#FDD0EC', // Magenta 200
			'color.link.pressed': '#FFECF8', // Magenta 100
		},
	},
};

export const NO_BACKGROUND_CONFIG: ColorBackgroundConfig = {
	name: 'no-background',
	brightness: 'high',
	theme: {
		light: BASE_THEME,
		dark: BASE_THEME,
	},
};

// Gradients
const GRADIENT_GRAY_TO_BLACK_META: GradientBackgroundConfig = {
	name: 'gradient-gray-to-black',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#44546F', // Neutral 800
			'elevation.surface': 'linear-gradient(135deg, #0B1D3A 0%, #505F79 100%)',
		},
		dark: {
			'color.elevation.surface': '#44546F', // Neutral 800
			'elevation.surface': 'linear-gradient(135deg, #0B1D3A 0%, #505F79 100%)',
		},
	},
};

const GRADIENT_DARK_TO_MEDIUM_PURPLE_META: GradientBackgroundConfig = {
	name: 'gradient-dark-to-medium-purple',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#44546F', // Neutral 800
			'elevation.surface': 'linear-gradient(135deg, #002D6D 0%, #61013C 100%)',
		},
		dark: {
			'color.elevation.surface': '#44546F', // Neutral 800
			'elevation.surface': 'linear-gradient(135deg, #002D6D 0%, #61013C 100%)',
		},
	},
};

const GRADIENT_LIGHT_TO_DARK_BLUE_META: GradientBackgroundConfig = {
	name: 'gradient-light-to-dark-blue',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#44546F', // Neutral 800
			'elevation.surface': 'linear-gradient(135deg, #001F4A 0%, #0033B7 100%)',
		},
		dark: {
			'color.elevation.surface': '#44546F', // Neutral 800
			'elevation.surface': 'linear-gradient(135deg, #001F4A 0%, #0033B7 100%)',
		},
	},
};

const GRADIENT_BLUE_TO_TEAL_META: GradientBackgroundConfig = {
	name: 'gradient-blue-to-teal',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#44546F', // Neutral 800
			'elevation.surface': 'linear-gradient(135deg, #000D81 0%, #1492A3 100%)',
		},
		dark: {
			'color.elevation.surface': '#44546F', // Neutral 800
			'elevation.surface': 'linear-gradient(135deg, #000D81 0%, #1492A3 100%)',
		},
	},
};

const GRADIENT_PURPLE_TO_MAGENTA_META: GradientBackgroundConfig = {
	name: 'gradient-purple-to-magenta',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#44546F', // Neutral 800
			'elevation.surface': 'linear-gradient(135deg, #13006F 0%, #CD519D 100%)',
		},
		dark: {
			'color.elevation.surface': '#44546F', // Neutral 800
			'elevation.surface': 'linear-gradient(135deg, #13006F 0%, #CD519D 100%)',
		},
	},
};

const GRADIENT_MAGENTA_TO_ORANGE_META: GradientBackgroundConfig = {
	name: 'gradient-magenta-to-orange',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#44546F', // Neutral 800
			'elevation.surface': 'linear-gradient(135deg, #7D004B 28.5%, #BF3900 100%)',
		},
		dark: {
			'color.elevation.surface': '#44546F', // Neutral 800
			'elevation.surface': 'linear-gradient(135deg, #7D004B 28.5%, #BF3900 100%)',
		},
	},
};

const GRADIENT_DARK_TO_LIGHT_RED_META: GradientBackgroundConfig = {
	name: 'gradient-dark-to-light-red',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#44546F', // Neutral 800
			'elevation.surface': 'linear-gradient(135deg, #150000 0%, #CA0000 100%)',
		},
		dark: {
			'color.elevation.surface': '#44546F', // Neutral 800
			'elevation.surface': 'linear-gradient(135deg, #150000 0%, #CA0000 100%)',
		},
	},
};

const GRADIENT_DARK_TO_LIGHT_ORANGE_META: GradientBackgroundConfig = {
	name: 'gradient-dark-to-light-orange',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#44546F', // Neutral 800
			'elevation.surface': 'linear-gradient(135deg, #650101 0%, #C75300 99.75%)',
		},
		dark: {
			'color.elevation.surface': '#44546F', // Neutral 800
			'elevation.surface': 'linear-gradient(135deg, #650101 0%, #C75300 99.75%)',
		},
	},
};

const GRADIENT_DARK_GREEN_TO_LIME_META: GradientBackgroundConfig = {
	name: 'gradient-dark-green-to-lime',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#44546F', // Neutral 800
			'elevation.surface': 'linear-gradient(135deg, #072C14 0%, #457200 100%)',
		},
		dark: {
			'color.elevation.surface': '#44546F', // Neutral 800
			'elevation.surface': 'linear-gradient(135deg, #072C14 0%, #457200 100%)',
		},
	},
};

const GRADIENT_DARK_TO_MEDIUM_TEAL_META: GradientBackgroundConfig = {
	name: 'gradient-dark-to-medium-teal',
	brightness: 'low',
	theme: {
		light: {
			'color.elevation.surface': '#44546F', // Neutral 800
			'elevation.surface': 'linear-gradient(135deg, #00152D 0%, #02607E 100%)',
		},
		dark: {
			'color.elevation.surface': '#44546F', // Neutral 800
			'elevation.surface': 'linear-gradient(135deg, #00152D 0%, #02607E 100%)',
		},
	},
};

// TODO https://jbusiness.atlassian.net/browse/JWMFRONT-947 remove old themes when backend migration is complete
export const BACKGROUND_CONFIGS_OLD = [
	WHITE_BACKGROUND_CONFIG,
	LIGHT_PURPLE_BACKGROUND_CONFIG,
	LIGHT_BLUE_BACKGROUND_CONFIG,
	LIGHT_TEAL_BACKGROUND_CONFIG,
	LIGHT_GREEN_BACKGROUND_CONFIG,
	LIGHT_YELLOW_BACKGROUND_CONFIG,
	LIGHT_ORANGE_BACKGROUND_CONFIG,
	LIGHT_RED_BACKGROUND_CONFIG,
	LIGHT_MAGENTA_BACKGROUND_CONFIG,
	DARK_BACKGROUND_CONFIG,
	PURPLE_BACKGROUND_CONFIG,
	BLUE_BACKGROUND_CONFIG,
	TEAL_BACKGROUND_CONFIG,
	GREEN_BACKGROUND_CONFIG,
	YELLOW_BACKGROUND_CONFIG,
	ORANGE_BACKGROUND_CONFIG,
	RED_BACKGROUND_CONFIG,
	MAGENTA_BACKGROUND_CONFIG,
];

export const BACKGROUND_COLOR_CONFIGS = [
	LIGHTEST_GRAY_BACKGROUND_CONFIG,
	LIGHTEST_PURPLE_BACKGROUND_CONFIG,
	LIGHTEST_BLUE_BACKGROUND_CONFIG,
	LIGHTEST_TEAL_BACKGROUND_CONFIG,
	LIGHTEST_GREEN_BACKGROUND_CONFIG,
	LIGHTEST_LIME_BACKGROUND_CONFIG,
	LIGHTEST_YELLOW_BACKGROUND_CONFIG,
	LIGHTEST_ORANGE_BACKGROUND_CONFIG,
	LIGHTEST_RED_BACKGROUND_CONFIG,
	LIGHTEST_MAGENTA_BACKGROUND_CONFIG,
	LIGHTER_GRAY_BACKGROUND_CONFIG,
	LIGHTER_PURPLE_BACKGROUND_CONFIG,
	LIGHTER_BLUE_BACKGROUND_CONFIG,
	LIGHTER_TEAL_BACKGROUND_CONFIG,
	LIGHTER_GREEN_BACKGROUND_CONFIG,
	LIGHTER_LIME_BACKGROUND_CONFIG,
	LIGHTER_YELLOW_BACKGROUND_CONFIG,
	LIGHTER_ORANGE_BACKGROUND_CONFIG,
	LIGHTER_RED_BACKGROUND_CONFIG,
	LIGHTER_MAGENTA_BACKGROUND_CONFIG,
	DARKER_GRAY_BACKGROUND_CONFIG,
	DARKER_PURPLE_BACKGROUND_CONFIG,
	DARKER_BLUE_BACKGROUND_CONFIG,
	DARKER_TEAL_BACKGROUND_CONFIG,
	DARKER_GREEN_BACKGROUND_CONFIG,
	DARKER_LIME_BACKGROUND_CONFIG,
	DARKER_YELLOW_BACKGROUND_CONFIG,
	DARKER_ORANGE_BACKGROUND_CONFIG,
	DARKER_RED_BACKGROUND_CONFIG,
	DARKER_MAGENTA_BACKGROUND_CONFIG,
	DARKEST_GRAY_BACKGROUND_CONFIG,
	DARKEST_PURPLE_BACKGROUND_CONFIG,
	DARKEST_BLUE_BACKGROUND_CONFIG,
	DARKEST_TEAL_BACKGROUND_CONFIG,
	DARKEST_GREEN_BACKGROUND_CONFIG,
	DARKEST_LIME_BACKGROUND_CONFIG,
	DARKEST_YELLOW_BACKGROUND_CONFIG,
	DARKEST_ORANGE_BACKGROUND_CONFIG,
	DARKEST_RED_BACKGROUND_CONFIG,
	DARKEST_MAGENTA_BACKGROUND_CONFIG,
];

export const SOLID_COLOR_BACKGROUND_CONFIGS = [...BACKGROUND_COLOR_CONFIGS, NO_BACKGROUND_CONFIG];

export const GRADIENT_BACKGROUND_CONFIGS = [
	GRADIENT_DARK_TO_LIGHT_RED_META,
	GRADIENT_MAGENTA_TO_ORANGE_META,
	GRADIENT_DARK_TO_LIGHT_ORANGE_META,
	GRADIENT_DARK_GREEN_TO_LIME_META,
	GRADIENT_BLUE_TO_TEAL_META,
	GRADIENT_DARK_TO_MEDIUM_TEAL_META,
	GRADIENT_LIGHT_TO_DARK_BLUE_META,
	GRADIENT_DARK_TO_MEDIUM_PURPLE_META,
	GRADIENT_PURPLE_TO_MAGENTA_META,
	GRADIENT_GRAY_TO_BLACK_META,
];

export const BACKGROUND_CONFIGS: Array<ColorBackgroundConfig | GradientBackgroundConfig> = [
	...GRADIENT_BACKGROUND_CONFIGS,
	...SOLID_COLOR_BACKGROUND_CONFIGS,
];

// TODO https://jbusiness.atlassian.net/browse/JWMFRONT-947 remove old theme mappings when backend migration is complete
export const OLD_TO_NEW_BACKGROUND_CONFIG_MAP: Record<OldThemeName, ColorBackgroundConfig> = {
	white: NO_BACKGROUND_CONFIG,
	'light-purple': LIGHTEST_PURPLE_BACKGROUND_CONFIG,
	'light-blue': LIGHTEST_BLUE_BACKGROUND_CONFIG,
	'light-teal': LIGHTEST_TEAL_BACKGROUND_CONFIG,
	'light-green': LIGHTEST_GREEN_BACKGROUND_CONFIG,
	'light-yellow': LIGHTEST_YELLOW_BACKGROUND_CONFIG,
	'light-orange': LIGHTEST_ORANGE_BACKGROUND_CONFIG,
	'light-red': LIGHTEST_RED_BACKGROUND_CONFIG,
	'light-magenta': LIGHTEST_MAGENTA_BACKGROUND_CONFIG,
	dark: DARKEST_GRAY_BACKGROUND_CONFIG,
	purple: DARKER_PURPLE_BACKGROUND_CONFIG,
	blue: DARKER_BLUE_BACKGROUND_CONFIG,
	teal: DARKER_TEAL_BACKGROUND_CONFIG,
	green: DARKER_GREEN_BACKGROUND_CONFIG,
	yellow: DARKER_YELLOW_BACKGROUND_CONFIG,
	orange: DARKER_ORANGE_BACKGROUND_CONFIG,
	red: DARKER_RED_BACKGROUND_CONFIG,
	magenta: DARKER_MAGENTA_BACKGROUND_CONFIG,
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const DEFAULT_BACKGROUND_CONFIG = NO_BACKGROUND_CONFIG as ColorBackgroundConfig;

export const BANNER_HEIGHT = '--jwm-banner-height';
export const BUSINESS_BANNER_HEIGHT = `var(${BANNER_HEIGHT}, 0px)`;
export const HORIZONTAL_NAV_HEIGHT = 95;
export const BUSINESS_WRAPPER_PADDING = 24;
export const BUSINESS_NAV_HEIGHT = `calc(${HORIZONTAL_NAV_HEIGHT}px + ${BUSINESS_BANNER_HEIGHT})`;
export const BUSINESS_WRAPPER_HEIGHT = `calc(100vh - ${PAGE_LAYOUT_OFFSET_TOP} - ${BUSINESS_NAV_HEIGHT})`;
export const BUSINESS_WRAPPER_WITH_BACKGROUND_HEIGHT = `calc(100vh - ${PAGE_LAYOUT_OFFSET_TOP} - ${BUSINESS_NAV_HEIGHT} - ${
	BUSINESS_WRAPPER_PADDING * 2
}px)`;
export const BUSINESS_WRAPPER_WIDTH = `calc(100vw - ${UNSAFE_DO_NOT_USE_LEFT_PANEL_WIDTH} - ${UNSAFE_DO_NOT_USE_LEFT_SIDEBAR_WIDTH} - ${UNSAFE_DO_NOT_USE_RIGHT_PANEL_WIDTH} - ${UNSAFE_DO_NOT_USE_RIGHT_SIDEBAR_WIDTH}) !important`;
export const BUSINESS_WRAPPER_WITH_BACKGROUND_WIDTH = `calc(100vw - ${UNSAFE_DO_NOT_USE_LEFT_PANEL_WIDTH} - ${UNSAFE_DO_NOT_USE_LEFT_SIDEBAR_WIDTH} - ${UNSAFE_DO_NOT_USE_RIGHT_PANEL_WIDTH} - ${UNSAFE_DO_NOT_USE_RIGHT_SIDEBAR_WIDTH} - ${
	BUSINESS_WRAPPER_PADDING * 2
}px) !important`;
