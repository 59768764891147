import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { getNavigationSidebarGlobalSettingsResources } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import AsyncSidebarGlobalSettingsIssues from '@atlassian/jira-navigation-apps-sidebar-global-settings-issues/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global';
import { LegacyPage } from '@atlassian/jira-router-routes-common/src/ui/legacy-page/main.tsx';
import { workflowDesignerRoute } from '@atlassian/jira-router-routes-legacy-workflow-designer-routes';
import { LegacyWorkflowDesigner } from './ui';

export const workflowDesignerRouteEntry = createEntry(workflowDesignerRoute, {
	component: componentWithCondition(
		() => ff('pce.helix.admin-pages.edit-workflow-page'),
		LegacyWorkflowDesigner,
		LegacyPage,
	),
	layout: globalSettingsLayout,
	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsIssues,
	},
	resources: [
		...getNavigationResources(),
		navigationSidebarGlobalResource,
		...getNavigationSidebarGlobalSettingsResources(),
	],
});
